<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
// import { useRoute, useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { env_config } from "../../config";
import { enterFalseFullScreen, toggleFalseFullScreen, leavePlayersPage } from "../utils/players";
import { v4 as uuidv4 } from "uuid";
import { getUserInfo } from "../utils/auth";
import Player from "../components/Player.vue";
import Cam from "../components/Cam.vue";
import OvenLiveKit from "ovenlivekit";
import { RepositoryFactory } from "../repositories/RepositoryFactory";

const store = useStore();
console.log(store.state.isConfirmed);

const route = useRoute();
const roomId = route.params.id;
const roomName = route.query.roomName;

const { user_mode } = getUserInfo();
const userMode = ref(user_mode);

const RecordingsRepository = RepositoryFactory.get("recordings");

const cams = ref(["player-id1", "player-id2", "player-id3"]);
let index = ref(0);

let isFalseFullScreen = ref();
let recordingsDialogVisible = ref(false);
let recordingsDialogHeader = ref("");
let recordingsDialogText = ref("");
let recordingsDialogErrorDetail = ref("");

// Access players' functions by referencing them
const p1 = ref(null);
const p2 = ref(null);
const p3 = ref(null);

const players = [p1, p2, p3];

const activeUsers = ref(0);

// Display only one player at a time
function setPlayers() {
    for (let i = 0; i < 3; i++) {
        let cam = document.getElementById(cams.value[i]);
        if (i != index.value) {
            cam.parentNode.style.display = "none";
        } else {
            cam.parentNode.style.display = "block";
        }
    }
}

// Get the same button of the 3 players to iterate them
function getButtons() {
    const previousButtons = document.querySelectorAll("button.op-button.op-previous-cam-button");
    const nextButtons = document.querySelectorAll("button.op-button.op-next-cam-button");
    const soundButtons = document.querySelectorAll("button.op-button.op-volume-button");
    const chatButtons = document.querySelectorAll("button.op-button.op-chat-button");
    const fullScreenButtons = document.querySelectorAll("button.op-button.op-fullscreen-button");
    const volumeSlider = document.querySelectorAll("div.op-volume-slider-container .op-volume-silder");
    return { previousButtons, nextButtons, soundButtons, chatButtons, fullScreenButtons, volumeSlider };
}

// Set fullscreen with support on all the browsers
// (this may be deprecated in the future if fullscreen is removed)
function setFullScreen(index) {
    const element = document.getElementById(cams.value[index]);
    const requestFullScreen = element.requestFullscreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;
    requestFullScreen.call(element);
}

// --- BIDIRECTIONALITY --- //

// Connect to Websocket Server
const wsId = ref(uuidv4());
let state = ref();
let streamerId = ref();
let op = ref();

watch(state, (currentValue) => {
    if (currentValue == "stopped") {
        if (op.value) {
            op.value.remove();
        }
    }
});

function changeStateToStopped() {
    state.value = "stopped";
}

function changeStateToStreaming() {
    state.value = "streaming";
}

function communicateState(emergency = "false") {
    connection.send(JSON.stringify({ state: state.value, emergency: emergency }));
}

console.log("Starting connection to WebSocket Server...");

// const connection = new WebSocket(`ws://localhost:8001/ws/webcam/${roomId}?ws-id=${wsId.value}`);
const { hostname } = new URL(env_config.API_BASE_DOMAIN);
const connection = new WebSocket(`wss://${hostname}/ws/webcam/${roomId}?ws-id=${wsId.value}`);

connection.onopen = function () {
    console.log("Successfully connected to the WebSocket Server");
};

connection.onmessage = (event) => {
    const data = JSON.parse(event.data);
    // console.log(data);
    state.value = data.state;
    streamerId.value = data.ws_id;
};

connection.onerror = function () {
    console.log("websocket error");
    if (connection) {
        changeStateToStopped();
        communicateState();
    }
};

// Remove OvenLiveKit streaming if connection with WebSocket closes
connection.onclose = () => {
    if (op.value) {
        op.value.remove();
        changeStateToStopped();
        communicateState();
    }
};

function startCam() {
    /* OvenLiveKit config */
    var config = {
        callbacks: {
            connected: () => {
                console.log("CALLBACK CONNECTED");
                changeStateToStreaming();
                communicateState();
            },
            connectionClosed: () => {
                console.log("CALLBACK CONNECTION CLOSED");
                connection.close();
                changeStateToStopped();
                communicateState();
            },
            error: (event) => {
                if (event == "Cannot create offer") {
                    console.log(event);
                }
            }
        }
    };

    /* OvenLiveKit initialization */
    let ovenLivekit = OvenLiveKit.create(config);
    op.value = ovenLivekit;
    ovenLivekit
        .getUserMedia({
            audio: true,
            video: true
        })
        .then(() => {
            if (connection.readyState !== WebSocket.CLOSED || connection.readyState !== WebSocket.CLOSING) {
                // ovenLivekit.startStreaming("wss://ome-test-2.bemyvega.dev/app/TZP2jwRJFmxjHDGgrGzb34-user?direction=send");
                ovenLivekit.startStreaming(`wss://${env_config.WEBRTC_ROOT}/app/${roomId}-user?direction=send`);
            } else {
                stopCam();
            }
        });
}
function stopCam() {
    changeStateToStopped();
    communicateState();
}

function emergencyStop() {
    connection.send(JSON.stringify({ state: "streaming", emergency: "true" }));
}

async function getStreamsStatistics() {
    try {
        const response = await RecordingsRepository.getStreamsStatistics(roomId);
        const api = response.data;
        activeUsers.value = api.totalConnections;

        return response.status;
    } catch (error) {
        const detail = error.response.data.detail;
        console.log(detail);
    }
}

const streamInterval = ref();
streamInterval.value = setInterval(() => {
    getStreamsStatistics();
}, 5000);

// Recordings
async function startRecording() {
    try {
        const response = await RecordingsRepository.startHLSDump(roomId);
        console.log(response.status);

        recordingsDialogVisible.value = true;

        recordingsDialogHeader.value = "Iniciando grabación";
        recordingsDialogText.value = `La grabación de la sesión "${roomName}" se ha iniciado correctamente.`;
        recordingsDialogErrorDetail.value = "";

        return response.status;
    } catch (error) {
        const detail = error.response.data.detail;
        console.log(error);

        recordingsDialogVisible.value = true;

        recordingsDialogHeader.value = `Error ${error.response.status}`;
        recordingsDialogText.value = `No se ha podido comenzar la grabación de la sesión "${roomName}".`;
        recordingsDialogErrorDetail.value = detail;
    }
}

async function stopRecording() {
    try {
        const response = await RecordingsRepository.stopHLSDump(roomId);
        console.log(response.status);

        recordingsDialogVisible.value = true;
        recordingsDialogHeader.value = "Terminando grabación";
        recordingsDialogText.value = `La grabación de la sesión "${roomName}" se ha completado correctamente.`;
        recordingsDialogErrorDetail.value = "";

        return response.status;
    } catch (error) {
        const detail = error.response.data.detail;
        console.log(error);

        recordingsDialogVisible.value = true;

        recordingsDialogHeader.value = `Error ${error.response.status}`;
        recordingsDialogText.value = `No se ha podido terminar la grabación de la sesión "${roomName}".`;
        recordingsDialogErrorDetail.value = detail;
    }
}

onMounted(() => {
    setPlayers();
    isFalseFullScreen.value = enterFalseFullScreen();

    // Change cams
    document.body.addEventListener("click", function (event) {
        let { previousButtons, nextButtons } = getButtons();
        for (let i = 0; i < previousButtons.length; i++) {
            // Change to previous cam
            if (previousButtons[i].contains(event.target)) {
                index.value = index.value - 1;
                if (index.value < 0) {
                    index.value = 2;
                }
                setPlayers();
                if (window.innerHeight == screen.height) {
                    document.exitFullscreen();
                    setTimeout(() => setFullScreen(index.value), 500);
                }
                break;

                // Change to next cam
            } else if (nextButtons[i].contains(event.target)) {
                index.value = index.value + 1;
                if (index.value > 2) {
                    index.value = 0;
                }
                setPlayers();
                if (window.innerHeight == screen.height) {
                    document.exitFullscreen();
                    setTimeout(() => setFullScreen(index.value), 500);
                }
                break;
            }
        }
    });

    // Mute or unmute all players at once
    document.body.addEventListener("click", function (event) {
        let { soundButtons } = getButtons();
        for (let i = 0; i < soundButtons.length; i++) {
            if (soundButtons[i].contains(event.target)) {
                let isMute = players[i].value.getMute();
                for (let n = 0; n < 3; n++) {
                    players[n].value.setMute(isMute);
                }
                break;
            }
        }
    });

    // Control the volume of all players at once
    document.body.addEventListener("click", function (event) {
        let { volumeSlider } = getButtons();
        for (let i = 0; i < volumeSlider.length; i++) {
            if (volumeSlider[i].contains(event.target)) {
                let volume = players[i].value.getVolume();
                console.log("Volume is " + volume);
                for (let n = 0; n < 3; n++) {
                    players[n].value.setMute(false);
                    players[n].value.setVolume(volume);
                }
                break;
            }
        }
    });

    // Hide or display chat
    document.body.addEventListener("click", function (event) {
        let { chatButtons } = getButtons();
        for (let i = 0; i < chatButtons.length; i++) {
            if (chatButtons[i].contains(event.target)) {
                // let chat = document.getElementById("chat");
                let chat = document.querySelector("div.chat-column");
                if (chat.style.display != "none") {
                    chat.style.display = "none";
                } else {
                    chat.style.display = "block";
                }
                break;
            }
        }
    });

    // Overwrite fullscreen mode with false fullscreen
    document.body.addEventListener(
        "click",
        function (event) {
            let { fullScreenButtons } = getButtons();
            for (let i = 0; i < fullScreenButtons.length; i++) {
                if (fullScreenButtons[i].contains(event.target)) {
                    event.stopPropagation();
                    isFalseFullScreen.value = toggleFalseFullScreen(isFalseFullScreen.value);
                    if (window.innerHeight == screen.height) {
                        document.exitFullscreen();
                    }
                }
            }
        },
        true
    );
});

onUnmounted(() => {
    if (state.value == "streaming" && wsId.value == streamerId.value) {
        stopCam();
    }
    isFalseFullScreen.value = leavePlayersPage();
    if (streamInterval.value) {
        clearInterval(streamInterval.value);
    }
});
</script>

<template>
    <main>
        <Dialog v-model:visible="recordingsDialogVisible" :header="recordingsDialogHeader" style="width: 50vw" modal>
            <p class="m-0">{{ recordingsDialogText }}</p>
            <br />
            <p v-if="recordingsDialogErrorDetail != ''" class="m-0">Details: {{ recordingsDialogErrorDetail }}</p>
            <template #footer>
                <Button label="OK" icon="pi pi-check" autofocus @click="recordingsDialogVisible = false" />
            </template>
        </Dialog>
        <div>
            <div class="container sm:flex sm:flex-wrap md:grid md:grid-nogutter">
                <div class="player-column sm:col-12 md:col">
                    <!-- Players -->
                    <Player :id="cams[0]" ref="p1" :text="roomName + '<br><br>' + 'Profesor'" :sources="[{ type: 'webrtc', file: `wss://${env_config.WEBRTC_ROOT}/app/${roomId}-ptz` }]" />
                    <Player :id="cams[1]" ref="p2" :text="roomName + '<br><br>' + 'Pizarra'" :sources="[{ type: 'webrtc', file: `wss://${env_config.WEBRTC_ROOT}/app/${roomId}-fixed` }]" />
                    <Player :id="cams[2]" ref="p3" :text="roomName + '<br><br>' + 'Presentacion'" :sources="[{ type: 'webrtc', file: `wss://${env_config.WEBRTC_ROOT}/app/${roomId}-desktop` }]" />
                    <div id="floating-cam" class="floating-window sm:relative sm:col-12 sm:bottom-0 sm:left-0 sm:py-0 sm:w-20rem sm:h-12rem sm:m-auto sm:mb-0 sm:p-0 md:absolute md:bottom-0 md:right-0 md:left-0 md:w-20rem md:h-12rem md:mb-7 md:ml-2">
                        <Cam :id="`player-user-${roomId}`" :controls="false" :sources="[{ type: 'webrtc', file: `wss://${env_config.WEBRTC_ROOT}/app/${roomId}-user` }]" />
                    </div>
                    <div class="player-footer mt-2 grid">
                        <p class="mx-auto col-12 text-center" style="color: white">
                            <i class="pi pi-eye mr-2"></i>
                            <b>{{ activeUsers }}</b> viewers
                        </p>
                        <div class="col-12 grid justify-content-center gap-2">
                            <Button v-if="userMode == 'teacher'" id="emergency-stop-button" class="p-button-danger mr-6" label="Force Stop" @click="emergencyStop"></Button>
                            <Button id="start-cam-button" label="Start Cam" :disabled="state == `streaming`" :class="state == `streaming` ? `inactive` : `active`" @click="startCam"></Button>
                            <Button id="stop-cam-button" label="Stop Cam" :disabled="state != `streaming` || wsId != streamerId" :class="state == `streaming` && wsId == streamerId ? `active` : `inactive`" @click="stopCam"></Button>
                            <Button v-if="userMode == 'teacher'" id="start-recording" class="p-button-success ml-6" label="Start recording" @click="startRecording"></Button>
                            <Button v-if="userMode == 'teacher'" id="stop-recording" class="p-button-danger mr-6" label="Stop recording" @click="stopRecording"></Button>
                        </div>
                    </div>
                </div>

                <!-- Chat -->
                <div class="chat-column sm:col-12 sm:h-screen md:col-3">
                    <iframe id="chat" class="w-full sm:h-30rem sm:min-w-0 md:h-screen" src="https://socketio.bemyvega.com"> </iframe>
                </div>
            </div>
        </div>
    </main>
</template>

<style scoped>
main {
    background-color: black;
    height: 100vh;
}

.container {
    background-color: black;
}

.player-column {
    padding: 0;
}
.chat-column {
    padding: 0;
    background-color: black;
}
#chat {
    width: 100%;
    min-height: 30rem;
}

#floating-cam {
    z-index: 10;
}

/* Bidirectionality */
.cam-buttons {
    display: flex;
    justify-content: center;
    background-color: black;
}

.active {
    background-color: green;
    color: white;
    cursor: pointer;
}

.inactive {
    background-color: lightgrey;
    color: darkgrey;
    cursor: none;
}
</style>
